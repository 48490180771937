.css-1hbyad5-MuiTypography-root {
  display: none !important;
}

.css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton {
  display: none !important;
}

.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title {
  width: 100% !important;
}
